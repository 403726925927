<template>
  <UiSidePanelForm
    :model-value="modelValue"
    title="Create new deal"
    primary-button-text="Create Deal"
    :disabled="loading || !data.deals.length"
    secondary-button-text="Cancel"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="submit"
  >
    <form id="deal-form" class="h-full" @submit.prevent>
      <p class="mb-4">
        You marked this lead as <span class="text-subhead-1">WON</span> and will create a deal for it. You'll see the
        closed lead history connected to the deal in the <span class="text-subhead-1">Deals</span> tab.
      </p>
      <p class="text-body-2 mb-2 text-error-120">
        If the lead is up to buy multiple property units, split it into separate deals.
      </p>
      <UiTabsDynamicTabs
        :active-tab="activeTabId"
        :tabs="tabsToRender"
        numbered-tabs
        @set-active-tab="setActiveTab"
        @add-tab="addTab"
        @close-tab="closeTab"
      />
      <hr class="mb-8 text-black-10" />
      <div v-for="(deal, i) in data.deals" :key="i">
        <Transition name="fade" mode="out-in">
          <div v-show="activeTabIdIndex === i" class="flex flex-col gap-4">
            <UiInputTextField
              :id="`${i}_buyer_name`"
              v-model="deal.name"
              :placeholder="$t('deal_name')"
              name="buyer_name"
              :label="$t('buyer_name') + ' *'"
              :error="useGetFieldErrorsByIndex(v$, 'deals', 'name', i)"
              compact
            />
            <UiInputDatePicker
              v-model="deal.booking_date"
              :name="`${i}_dateRange`"
              :start-with-placeholder="false"
              :placeholder="$t('select_date')"
              :label="$t('booking_date')"
              :error="useGetFieldErrorsByIndex(v$, 'deals', 'booking_date', i)"
              compact
            />
            <UiInputSelect
              :id="`${i}_project`"
              v-model="deal.project_id"
              :items="projectItems"
              add-new
              name="project"
              :placeholder="$t('select_from_list_or_type')"
              :label="$t('project') + ' *'"
              :error="useGetFieldErrorsByIndex(v$, 'deals', 'project_id', i)"
              compact
              @update:model-value="addCustomProject($event, i)"
            />
            <UiInputTextField
              :id="`${i}_unit_number`"
              v-model="deal.unit_number"
              :placeholder="$t('add_unit_number')"
              name="unit_number"
              :label="$t('unit_number') + ' *'"
              :error="useGetFieldErrorsByIndex(v$, 'deals', 'unit_number', i)"
              compact
            />
            <UiInputTextField
              :id="`${i}_unit_price`"
              v-model="deal.unit_price"
              :placeholder="$t('unit_price')"
              type="number"
              suffix="AED"
              name="unit_price"
              :label="$t('unit_price') + ' *'"
              :error="useGetFieldErrorsByIndex(v$, 'deals', 'unit_price', i)"
              compact
            />
            <UiInputTextField
              :id="`${i}_brokerage_commission`"
              v-model="deal.commission"
              :placeholder="$t('add_commission')"
              type="percentage"
              suffix="%"
              name="brokerage_commissions"
              :label="$t('brokerage_commission') + ' *'"
              :error="useGetFieldErrorsByIndex(v$, 'deals', 'commission', i)"
              compact
            />
            <UiInputSelect
              :id="`${i}_developer_id`"
              v-model="deal.developer_id"
              :items="developerItems"
              add-new
              name="developer_id"
              :placeholder="$t('select_from_list_or_type')"
              :label="$t('developer')"
              compact
              @update:model-value="addCustomDeveloper($event, i)"
            />
            <UiInputSelect
              :id="`${i}_source`"
              v-model="deal.source_id"
              :items="sourceItems"
              name="source"
              :placeholder="$t('select_from_list_or_type')"
              label="Source"
              :disabled="isReferralLead"
              compact
            />
            <UiInputSelect
              :id="`${i}_type`"
              v-model="deal.type_id"
              :items="typeItems"
              name="type"
              :placeholder="$t('select_from_list_or_type')"
              :label="$t('type')"
              compact
            />
            <UiInputSelect
              v-if="[ROLES.ADMIN, ROLES.CEO, ROLES.SDR].includes(authStore.getRoleCode!)"
              :id="`${i}_agent`"
              v-model="deal.agent_id"
              :items="agentItems"
              name="agent"
              :placeholder="$t('select_from_list_or_type')"
              :label="$t('agent')"
              compact
            />
            <UiInputSelect
              v-if="[ROLES.ADMIN, ROLES.CEO, ROLES.SDR].includes(authStore.getRoleCode!)"
              :id="`${i}_manager`"
              v-model="deal.manager_id"
              :items="managerItems"
              name="manager"
              :placeholder="$t('select_from_list_or_type')"
              :label="$t('manager')"
              compact
            />
          </div>
        </Transition>
      </div>
    </form>
  </UiSidePanelForm>
</template>

<script setup lang="ts">
import cloneDeep from 'lodash/cloneDeep'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, minValue, maxValue, numeric } from '@vuelidate/validators'
import { ROLES, FINANCE_COMMISSION_TYPES, CLOSE_STEPS, TAGS, FINANCE_CONTACT_TYPES } from '@/constants'
import { useUiStore } from '~/store/ui'
import { useAuthStore } from '@/store/auth'
import type { InputItem, Lead, Deal, Stage, LibraryItem, CreatingNewDealTab } from '@/types'

const uiStore = useUiStore()
const authStore = useAuthStore()

const { t: $t } = useI18n()

const SOURCE_PARTNER_CODE = 3

const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
  lead: Lead
  stage: Stage
}
const props = defineProps<Props>()

const loading = ref(false)
const brokerageCommissionTypeId = ref()
const newDealId = ref()
const projectItems = ref<InputItem[]>([])
const developerItems = ref<InputItem[]>([])
const sourceItems = ref<InputItem[]>([])
const typeItems = ref<InputItem[]>([])
const agentItems = ref<InputItem[]>([])
const managerItems = ref<InputItem[]>([])
const activeTabId = ref<number | null>(0)
const tabIndex = ref(0)

type DealForm = {
  deals: Partial<Deal>[]
}

const isReferralLead = computed(() => !!props.lead.tags.find((t) => t.code === TAGS.REFERRAL))
const activeTabIdIndex = computed(() => tabs.value.findIndex(({ id }) => activeTabId.value === id))

const dealItem: Partial<Deal> = {
  name: props.lead.name,
  agent_id: undefined,
  manager_id: undefined,
  project_id: undefined,
  unit_number: undefined,
  unit_price: undefined,
  commission: undefined,
  developer_id: undefined,
  source_id: undefined,
  type_id: undefined,
  booking_date: new Date(),
}

const data = ref<DealForm>({
  deals: [cloneDeep(dealItem)],
})

const primaryContactType = ref<LibraryItem>()

const rules = computed(() => ({
  deals: {
    $each: helpers.forEach({
      name: { required: helpers.withMessage('The name is required', required) },
      booking_date: { required: helpers.withMessage('The booking date is required', required) },
      project_id: { required: helpers.withMessage('The project is required', required) },
      unit_number: { required: helpers.withMessage('The unit number is required', required) },
      unit_price: { required: helpers.withMessage('The unit price is required', required) },
      commission: {
        required: helpers.withMessage('The brokerage commission is required', required),
        minValue: minValue(0),
        maxValue: maxValue(100),
        numeric,
      },
    }),
  },
}))

const v$ = useVuelidate(rules, data)

// Tabs

const tabs = ref<CreatingNewDealTab[]>([
  {
    id: tabIndex.value,
    text: $t('unit'),
    error: false,
  },
])

const setTabValidity = () => {
  let tabsHasError: null | number = null
  data.value.deals.forEach((deal, i) => {
    const fieldWithError = Object.keys(deal).find((key) => {
      return useGetFieldErrorsByIndex(v$.value, 'deals', key, i)
    })
    if ((tabsHasError === null || activeTabId.value === tabs.value[i].id) && fieldWithError) {
      tabsHasError = tabs.value[i].id
    }
    tabs.value[i].error = !!fieldWithError
  })

  if (tabsHasError !== null && tabsHasError !== activeTabId.value) {
    setActiveTab(tabsHasError)
  }
}

const tabsToRender = computed(() => tabs.value.filter((t) => (t.condition ? t.condition() : true)))

const addTab = () => {
  addDeal()
  tabs.value.push({
    id: ++tabIndex.value,
    text: $t('unit'),
    error: false,
  })
  setActiveTab(tabIndex.value)
}

const closeTab = (id: number) => {
  const deletedTabIndex = tabs.value.findIndex((item: CreatingNewDealTab) => item.id === id)
  deleteDeal(deletedTabIndex)
  tabs.value.splice(deletedTabIndex, 1)
  if (activeTabId.value === id) {
    nextTick(() => {
      activeTabId.value = tabs.value.length ? tabs.value[0].id : null
    })
  }
}

const setActiveTab = (id: number) => {
  activeTabId.value = null
  setTimeout(() => {
    activeTabId.value = id
  }, 500)
}

onNuxtReady(async () => {
  loading.value = true
  await Promise.all([
    getProjectItems(),
    getDeveloperItems(),
    getSourceItems(),
    getTypeItems(),
    getCommissionTypes(),
    getUserItems(),
    getContactTypes(),
  ])
  setDefaultValues()
  loading.value = false
})

const setDefaultValues = () => {
  dealItem.source_id = isReferralLead.value
    ? sourceItems.value.find((s) => s.value === SOURCE_PARTNER_CODE)?.value
    : undefined
  dealItem.agent_id = authStore.getIsAgent ? authStore.getUser?.id! : undefined

  data.value.deals.forEach((deal) => {
    deal.source_id = dealItem.source_id
    deal.agent_id = dealItem.agent_id
  })
}

const getProjectItems = async () => {
  projectItems.value = await useGetFinanceProjectsItems()
}

const getDeveloperItems = async () => {
  developerItems.value = await useGetFinanceDevelopersItems()
}

const getSourceItems = async () => {
  const sources = await useFinanceDealSources()
  sourceItems.value = useSerializeLibraryItems(sources)
}

const getTypeItems = async () => {
  typeItems.value = await useGetFinanceDealTypesItems()
}

const getCommissionTypes = async () => {
  const response = await useFinanceCommissionTypes()
  brokerageCommissionTypeId.value = response.find((c) => c.code === FINANCE_COMMISSION_TYPES.BROKERAGE)?.id
}

const getUserItems = async () => {
  agentItems.value = await useGetAgentsAndManagersItems()
  managerItems.value = await useGetManagersItems()
  const users = await useGetUsers()
  const adminUsers = useSerializeLibraryItems(users.filter((user) => user.role?.code === ROLES.ADMIN))
  managerItems.value.push(...adminUsers)
}

const getContactTypes = async () => {
  const types = await useFinanceContactTypes()
  primaryContactType.value = types.find((t) => t.code === FINANCE_CONTACT_TYPES.PRIMARY)
}

const goToDeal = () => {
  navigateTo(`/deals/${newDealId.value}`, {
    open: {
      target: '_blank',
    },
  })
}

const addDeal = () => {
  data.value.deals.push(cloneDeep(dealItem))
}

const deleteDeal = (index: number) => {
  data.value.deals.splice(index, 1)
}

const addCustomProject = async (name: string, index: number) => {
  if (!projectItems.value.find((d) => d.value === name)) {
    try {
      const response = await useAddDealProject(name)
      projectItems.value.unshift({ text: response.data.name, value: response.data.id })
      data.value.deals[index].project_id = response.data.id
    } catch (err: any) {
      uiStore.showSnackBanner(err.message, 'error')
    }
  }
}

const addCustomDeveloper = async (name: string, index: number) => {
  if (!developerItems.value.find((d) => d.value === name)) {
    try {
      const response = await useAddDealDeveloper(name)
      developerItems.value.unshift({ text: response.data.name, value: response.data.id })
      data.value.deals[index].developer_id = response.data.id
    } catch (err: any) {
      uiStore.showSnackBanner(err.message, 'error')
    }
  }
}

const submit = async () => {
  const isValid = await v$.value.$validate()
  if (!isValid) {
    setTabValidity()
    return
  }
  try {
    loading.value = true
    const payload = data.value.deals.map((d) => ({
      ...d,
      contact: { name: d.name, type_id: primaryContactType.value?.id },
      commission: { percent: d.commission, type_id: brokerageCommissionTypeId.value },
    }))
    const payloadClosing = {
      pipeline_stage_id: props.stage?.id,
      pipeline_step_id: props.stage.steps?.find((s) => s.code === CLOSE_STEPS.WON)?.id,
    }

    const response = await useCreateNewDeals(props.lead.id, payload)

    if (response) {
      newDealId.value = response.data.id
      const responseClosing = await useCloseLead(props.lead.id, payloadClosing)
      uiStore.showSnackBanner(`Deal(s) ${props.lead.name} created.`, 'success', goToDeal, 'Open deal')
      emits('input', responseClosing.data)
      emits('update:modelValue', false)
    }
  } catch (error: any) {
    uiStore.showSnackBanner(error, 'error')
  } finally {
    loading.value = false
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
