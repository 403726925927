<template>
  <ul
    class="flex flex-nowrap items-start justify-start transition-opacity duration-200"
    :class="{ 'opacity-50': disabled }"
  >
    <li
      v-for="(tab, index) in tabs"
      :id="tab.id"
      :key="tab.id"
      class="relative flex h-[38px] cursor-pointer items-center truncate rounded-none border-none px-4 py-2 outline-none after:transition-all after:duration-200"
      :class="[
        {
          'after:absolute after:inset-x-0 after:bottom-0 after:z-10 after:mx-auto after:h-[2px] after:w-full after:rounded-full after:bg-primary-100 after:content-[\'\']':
            tab.id === activeTab,
          'after:absolute after:inset-x-0 after:bottom-0 after:z-10 after:mx-auto after:h-[2px] after:w-full after:rounded-full after:bg-primary-50 after:opacity-0 after:content-[\'\'] hover:after:opacity-100':
            tab.id !== activeTab,
          '!px-1': tabs.length > 3,
          'text-error-100  after:bg-error-100': tab.error,
        },
      ]"
      @click="emits('setActiveTab', tab.id)"
      @mouseover="tabHovered = tab.id"
      @mouseleave="tabHovered = null"
    >
      <h5 :class="tab.id === activeTab ? 'text-subhead-1 truncate capitalize' : 'truncate !font-normal capitalize'">
        {{ tabs.length > 5 ? `${index + 1}` : `${tab.text} ${index + 1}` }}
      </h5>
      <UiIcon
        name="small-close"
        :size="tabs.length > 5 ? 'sm' : 'xs'"
        class="ml-2 size-4 cursor-pointer opacity-0"
        :class="{
          '!ml-1': tabs.length > 2,
          '!ml-0': tabs.length > 4,
          'opacity-100': tabHovered === tab.id || tab.id === activeTab,
          'text-error-100': tab.error,
        }"
        @click.stop="$emit('closeTab', tab.id)"
      />
    </li>
    <li
      v-if="tabs.length < 10"
      id="addTab"
      key="addTab"
      class="relative flex h-[38px] cursor-pointer flex-nowrap rounded-none border-none px-4 py-2 outline-none after:transition-all after:duration-200"
      @click="$emit('addTab')"
    >
      <UiIcon name="big-add" class="mr-2 text-primary-100"></UiIcon>
      <h5 class="text-nowrap font-normal text-primary-100">{{ $t('add') }}</h5>
    </li>
  </ul>
</template>

<script setup lang="ts">
import type { Tab } from '@/types'

const emits = defineEmits(['setActiveTab', 'addTab', 'closeTab'])

type Props = {
  activeTab: number | null
  tabs: Tab[]
  alignLeft?: boolean
  bigFont?: boolean
  useRoute?: boolean
  numberedTabs?: boolean
  disabled?: boolean
}

defineProps<Props>()

const tabHovered = ref<number | string | null>(null)
</script>

<style scoped></style>
